import { createReducer, on } from '@ngrx/store';
import { decrement, increment, reset, retrievedBookList } from 'app/landing/state/landing.actions';
import {Book} from './book.model';

export const initialState = 0;

const counterReducer = createReducer(initialState,
  on(increment, state => state + 1),
  on(decrement, state => state - 1),
  on(reset, () => 0)
);

export function countReducer(state, action) {
  return counterReducer(state, action);
}

export const initialBookState: ReadonlyArray<Book> = [];

export const booksReducer = createReducer(
  initialBookState,
  on(retrievedBookList, (state, { book }) => [...book])
);
