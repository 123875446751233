<h1>Landing Page!</h1>
<button id="increment" (click)="increment()">Increment</button>

<div>Current Count: {{ count$ | async }}</div>

<button id="decrement" (click)="decrement()">Decrement</button>

<button id="reset" (click)="reset()">Reset Counter</button>

<hr/>
<button (click)="openDialog()">Open Dialog</button>
<mat-form-field>
  <mat-label>label of field</mat-label>
  <input matInput 
         type="text"
         placeholder="default mat-field"
         #x
  >
  <mat-hint align="start">hint on left</mat-hint>
  <mat-hint align="end">{{x.value.length}}</mat-hint>
</mat-form-field>

<h1 class="mat-display-1 mat-elevation-z2">If you can see Material Slider that means Angular Material is at work!!!</h1>
    <mat-slider min="1" max="100" step="1" value="1"></mat-slider>

<button (click)="getMe()">Get Me</button>
<button (click)="postMe()">Post Me</button>

 {{data}}
