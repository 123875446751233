import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MineService {
  constructor(private http: HttpClient) { }

  getMe(): Observable<any> {
     return this.http.get('/api/coffees/findall', {
       responseType: 'text'
     });
  }

  postMe(): Observable<any> {
    return this.http.post('/api/coffees', {
      boom: 'shaka laka',
      woww: 'really'
    }, {
      responseType: 'text'
    });
  }
}
