import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'app/page-not-found/page-not-found.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { LandingComponent } from 'app/landing/landing.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardGuard } from 'app-core/auth-guard.guard';

const appRoutes: Routes = [
  { path: 'sample',
    loadChildren: () => import('./@SampleModule/sample.module').then(module => module.SampleModule)
  },
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardGuard]
  },
  { path: 'login', component: LoginComponent},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
    enableTracing: false
}
    ),
    StoreRouterConnectingModule.forRoot(),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
