import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { increment } from '../state/landing.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mat-dialog-sample',
  templateUrl: './mat-dialog-sample.component.html',
  styleUrls: ['./mat-dialog-sample.component.css']
})
export class MatDialogSampleComponent implements OnInit {
  storeCount$: Observable<number>;
  dialogCount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MatDialogSampleComponent>,
    public store: Store<{count: number}>
  ) { }

  ngOnInit(): void {
    this.storeCount$ = this.store.pipe(select('count'));
    this.storeCount$.subscribe(c => this.dialogCount = c);
  }

  increment() {
    this.store.dispatch(increment());
  }

  close() {
    this.dialogRef.close('closed with close button');
  }

}
