import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  #isAuthenticated = false;

  set isAuthenticated(state: boolean) {
    this.#isAuthenticated = state;
    this.lsService.set('loggedIn', this.#isAuthenticated);
  }

  get isAuthenticated(): boolean {
    return this.lsService.get('loggedIn') ?? this.#isAuthenticated;
  }

  constructor(private lsService: LocalStorageService) {
    console.log('called')
  }


}
