<ng-container *ngIf="buttonMode">
    <button (click)="login(true)" *ngIf="!authenticated">Login</button>
    <button (click)="login(false)" *ngIf="authenticated">Logout</button>    
</ng-container>
<ng-container *ngIf="!buttonMode">
<div class="login-page">
    <form>
        <mat-card>
            <mat-card-header>
              <mat-card-title>Please Sign In</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput name="username" type="text" placeholder="Username">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput name="password" type="password" placeholder="Password">
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary">Sign In</button>
            </mat-card-actions>
          </mat-card>
    </form>
</div>
</ng-container>

