import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { increment, decrement, reset } from './state/landing.actions';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogSampleComponent } from './mat-dialog-sample/mat-dialog-sample.component';
import { MineService } from '../mine.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  count$: Observable<number>;
  data: any;

  constructor(
    private store: Store<{count: number}>,
    public dialog: MatDialog,
    private service: MineService
  ) { }

  ngOnInit(): void {
    this.count$ = this.store.pipe(select('count'));
  }

  increment() {
    this.store.dispatch(increment());
  }

  decrement() {
    this.store.dispatch(decrement());
  }

  reset() {
    this.store.dispatch(reset());
  }

  openDialog() {
    const dialogRef = this.dialog.open(MatDialogSampleComponent, {
      data: {
        count: this.count$
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log(data);
    });
  }

  throwErr() {
     throw new Error('error occurred');
  }

  getMe() {
    this.service.getMe().subscribe( data => {
      console.log('data', data);
      this.data = data;
    });
  }

  postMe() {
    this.service.postMe().subscribe( data => {
      console.log('data', data);
      this.data = data;
    });
  }
}
