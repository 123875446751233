import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() buttonMode = false;

  set authenticated(auth) {
    this.auth.isAuthenticated = auth;
  }

  get authenticated() {
    return this.auth.isAuthenticated;
  }

  constructor(private auth: AuthService, private router: Router) {
    console.log('login component loaded');
  }

  ngOnInit(): void {
  }

  login(auth) {
    this.authenticated = auth;

    if (auth) {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['/login']);
    }

  }

}
