<mat-sidenav-container>
  <mat-sidenav #sidenavTop 
               [opened]="toggleSidenav"
               [mode]="sidenavMode">
    <mat-nav-list>
      <a mat-list-item routerLink="/">Home</a>
      <a mat-list-item routerLink="/sample">load sample lazy module</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <header>
      <mat-progress-bar color="accent" [value]="loadingPercentage" mode="buffer" [bufferValue]="loadingPercentage+10"></mat-progress-bar>
      <mat-toolbar color="primary" class="mat-elevation-z2">
        <button mat-button (click)="sidenavTop.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        Layout
       <app-login buttonMode="true"></app-login>
      </mat-toolbar>
    </header>
    <main>
      <router-outlet></router-outlet>
    </main>
    <footer>
      Footer
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>