import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ang-ngrx-mat';
  toggleSidenav = true;
  loadingPercentage = 0;

  constructor(
    private breakpointObserver: BreakpointObserver
    ) {
    this.loadingProgress()
      .subscribe(x => this.loadingPercentage = x);
  }

  loadingProgress() {
    return interval(500)
      .pipe(
        map(i => i * 10),
        takeWhile(i => i < 101)
      );
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 1200px)'])
      .subscribe( (screen) => this.toggleSidenav = !screen.matches);
  }

  get sidenavMode() {
    return this.toggleSidenav ? 'side' : 'over';
  }
}
