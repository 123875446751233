import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from 'environments/environment';
import { countReducer } from 'app/landing/state/landing.reducer';

export interface State {

}

export const reducers: ActionReducerMap<State> = {
  count: countReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
